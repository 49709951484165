<template>
	<div class="wrapper">

		<!-- header部分 -->
		<header>
			<p>确认订单</p>
		</header>

		<!-- 订单信息部分 -->
		<div class="order-info">
			<h5>订单配送至：</h5>
			<div class="order-info-address" @click="toUserAddress()">
				<p>{{deliveryAddress !=null?deliveryAddress.address:"请选择送货地址"}}</p>
				<i class="fa fa-angle-right"></i>
			</div>
			<p>{{deliveryAddress.contactName}}
				{{deliveryAddress.contactSex==1?"先生":"女士"}}
				{{deliveryAddress.contactTel}}
			</p>
		</div>

		<h3>{{business.businessName}}</h3>

		<!-- 订单明细部分 -->
		<ul class="order-detailed">
			<li v-for="item in foodArr" :key="item.cartId">
				<div class="order-detailed-left">
					<img :src="item.food.foodImg">
					<p>{{item.food.foodName}} x {{item.quantity}}</p>
				</div>
				<p>&#165;{{item.food.foodPrice*item.quantity}}</p>
			</li>
		</ul>
		<div class="order-deliveryfee">
			<p>配送费</p>
			<p>&#165;{{business.deliveryPrice}}</p>
		</div>

		<!-- 合计部分 -->
		<div class="total">
			<div class="total-left">
				&#165;{{totalPrice}}
			</div>
			<div class="total-right" @click="toPayment()">
				去支付
			</div>
		</div>
	</div>
</template>

<script setup>
	// qs 传参序列化
	import qs from 'qs';
	// 路由跳转
	import {
		useRouter,
		useRoute
	} from 'vue-router';
	// 响应数据，多级传参
	import {
		ref,
		inject,
		computed
	} from 'vue';
	import {
		getLocalStorage,
		getSessionStorage
	} from '../common.js';

	const axios = inject("axios");
	const router = useRouter();
	const route = useRoute();
	const businessId = route.query.businessId;

	const user = getSessionStorage('user');
	const deliveryAddress = getLocalStorage(user.userId);
	console.log("看看" + deliveryAddress)

	// 商家信息
	const business = ref({});
	// 食品信息
	let foodArr = ref([]);
	// 食品总价格
	const totalPrice = computed(() => {
		let total = 0;
		for (let item of foodArr.value) {
			total += item.food.foodPrice * item.quantity;
		}
		return total + business.value.deliveryPrice;
	});

	// 定义方法  用于获取后端信息
	const init = () => {
		// 根据商家id从后端获取商家信息
		axios.post('BusinessController/getBusinessById', qs.stringify({
			businessId: businessId
		})).then((response) => {
			console.log("商家信息");
			console.log(response.data);
			business.value = response.data;
		}).catch((error) => {
			console.log(error);
		});
		// 购物车
		axios.post('CartController/listCart', qs.stringify({
			businessId: businessId,
			userId: user.userId,
		})).then((response) => {
			console.log('查询购物车信息');
			console.log(response.data);
			foodArr.value = response.data;
		}).catch((error) => {
			console.log(error);
		});
	}
	// 调用方法，初始化商家和信息
	init();

	const toUserAddress = () => {
		router.push({
			path: "/userAddress",
			query: {
				businessId: businessId
			}
		})
	}


	const toPayment = () => {
		axios.post('OrderController/createOrders', qs.stringify({
			userId: user.userId,
			businessId: businessId,
			orderTotal: totalPrice.value,
			daId: deliveryAddress.daId
		})).then((response) => {
			console.log("订单表")
			console.log(response.data);
			let orderId = response.data;
			if (orderId > 0) {
				// alert("创建订单成功!!!!!")
				router.push({
					path: "/payment",
					query: {
						orderId: orderId
					}
				});

			} else {
				alert("创建订单失败!!!!!")
			}
		}).catch((error) => {
			console.log(error);
		});
	}
</script>

<style scoped>
	/****************** 总容器 ******************/
	.wrapper {
		width: 100%;
		height: 100%;
	}

	/****************** header部分 ******************/
	.wrapper header {
		width: 100%;
		height: 12vw;
		background-color: #0097FF;
		color: #fff;
		font-size: 4.8vw;

		position: fixed;
		left: 0;
		top: 0;
		z-index: 1000;

		display: flex;
		justify-content: center;
		align-items: center;
	}

	/****************** 订单信息部分 ******************/
	.wrapper .order-info {
		/*注意这里，不设置高，靠内容撑开。因为地址有可能折行*/
		width: 100%;
		margin-top: 12vw;
		background-color: #0097EF;
		box-sizing: border-box;
		padding: 2vw;
		color: #fff;
	}

	.wrapper .order-info h5 {
		font-size: 3vw;
		font-weight: 300;
	}

	.wrapper .order-info .order-info-address {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		font-weight: 700;
		user-select: none;
		cursor: pointer;
		margin: 1vw 0;
	}

	.wrapper .order-info .order-info-address p {
		width: 90%;
		font-size: 5vw;
	}

	.wrapper .order-info .order-info-address i {
		font-size: 6vw;
	}

	.wrapper .order-info p {
		font-size: 3vw;
	}

	.wrapper h3 {
		box-sizing: border-box;
		padding: 3vw;
		font-size: 4vw;
		color: #666;
		border-bottom: solid 1px #DDD;
	}

	/****************** 订单明细部分 ******************/
	.wrapper .order-detailed {
		width: 100%;
	}

	.wrapper .order-detailed li {
		width: 100%;
		height: 16vw;
		box-sizing: border-box;
		padding: 3vw;
		color: #666;

		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.wrapper .order-detailed li .order-detailed-left {
		display: flex;
		align-items: center;
	}

	.wrapper .order-detailed li .order-detailed-left img {
		width: 10vw;
		height: 10vw;
	}

	.wrapper .order-detailed li .order-detailed-left p {
		font-size: 3.5vw;
		margin-left: 3vw;
	}

	.wrapper .order-detailed li p {
		font-size: 3.5vw;
	}

	.wrapper .order-deliveryfee {
		width: 100%;
		height: 16vw;
		box-sizing: border-box;
		padding: 3vw;
		color: #666;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 3.5vw;
	}

	/****************** 订单合计部分 ******************/
	.wrapper .total {
		width: 100%;
		height: 14vw;

		position: fixed;
		left: 0;
		bottom: 0;

		display: flex;
	}

	.wrapper .total .total-left {
		flex: 2;
		background-color: #505051;
		color: #fff;
		font-size: 4.5vw;
		font-weight: 700;
		user-select: none;

		display: flex;
		justify-content: center;
		align-items: center;
	}

	.wrapper .total .total-right {
		flex: 1;
		background-color: #38CA73;
		color: #fff;
		font-size: 4.5vw;
		font-weight: 700;
		user-select: none;
		cursor: pointer;

		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>